import React from 'react'
import { Form, Col } from 'react-bootstrap'

// componente input sem validacao
const SelectWithValidation = ({ 
    type, 
    Col, 
    label, 
    name, 
    placeholder, 
    value, 
    error,
    handleChange,
    options,
    typeColumn="md", 
    valueColumn
    }) => (
        typeColumn === "md"
        ?
        <Col md>
            <Form.Group as={Col} controlId={name}>
                <Form.Label>{label}</Form.Label>
                <Form.Select
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    isInvalid={!!error}
                >
                    {options.map((item, index) => (<option value={item}>{item}</option>))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Form.Group>
        </Col>
        :
        <Col xs={valueColumn}>
            <Form.Group as={Col} controlId={name}>
                <Form.Label>{label}</Form.Label>
                <Form.Select
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    isInvalid={!!error}
                >
                    {options.map((item, index) => (<option value={item}>{item}</option>))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Form.Group>
        </Col>
    )

export default SelectWithValidation;