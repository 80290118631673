import React from 'react'

import SignIn from '../../components/signIn/index.js'

const Login = () => {

    return (
        <div>
            <SignIn />
        </div>
    );
}

export default Login;
