
import React from 'react'

import HeaderAdmin from '../../components/headerAdmin';
import SaveConhecimentoTransporte from '../../components/saveConhecimentoTransporte';

import './styles.css'

const SaveCte = () => {

    return (
        <div>
            <HeaderAdmin />
            <SaveConhecimentoTransporte />
        </div>
    )
}

export default SaveCte;