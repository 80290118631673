import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top'  

import Login    from './features/login';
import Home     from './features/home';

import Payment from './features/payment';
import SaveCte from './features/savecte';
import HomeAdmin from './features/homeAdmin';
import CtesMissing from './features/ctesMissing';

import StoreProvider from './components/storage/provider';
import RoutesPrivate from './components/privateRouter/private';


export default function Routes(props) {

  return (
    <BrowserRouter>
        <StoreProvider>
          <ScrollToTop>
            <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/admin" exact component={Login} />
                <RoutesPrivate path="/registra_recibo" exact component={Payment}/>
                <RoutesPrivate path="/salva_conhecimento_transporte_eletronico" exact component={SaveCte}/>
                <RoutesPrivate path="/financeiro" exact component={HomeAdmin}/>
                <RoutesPrivate path="/conhecimento_transporte_eletronico_faltantes" exact component={CtesMissing} />
                
            </Switch>
          </ScrollToTop>
        </StoreProvider>
      </BrowserRouter>
  )
}
