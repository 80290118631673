
import React from 'react'

import FixPayment from '../../components/fixPayment';
import HeaderAdmin from '../../components/headerAdmin';

import './styles.css'

const Payment = () => {

    return (
        <div>
            <HeaderAdmin />
            <FixPayment />
        </div>
    )
}

export default Payment;