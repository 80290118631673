
import React, { useState, useRef } from 'react'
import { useHistory } from "react-router-dom";
import { Form, ProgressBar } from 'react-bootstrap';

import api from '../../services/api';

import './styles.css'

const SaveConhecimentoTransporte = () => {

    const [filesList, setFilesList] = useState([]);
    // const [files, setFiles] = useState();

    let history = useHistory();

    // Use a ref to access the current count value in
    // an async callback.
    const filesListRef = useRef(filesList);
    filesListRef.current = filesList;

    const submitDocs = (event) => {
        event.preventDefault()
        const files = event.target.files

        if (files) {

            for (let i = 0; i < files.length; i++) {
                setFilesList(filesList => [...filesList, {
                    file_name: files[i].name,
                    size: (files[i].size/1000).toFixed(1),
                    type: files[i].type,
                    status: 0,
                    variant: 'danger'
                }])

                setTimeout(() => {callApi(files[i])}, i * 500);
            }
        }

        // setFiles()
    }

    const callApi = (file) => {
        let formData = new FormData();

        formData.append('file', file)

        api.post('ctes', formData, {
            headers: { "Content-Type": "multipart/form-data" },
            params: {file_name: file.name}
        }).then(response => {

            const file_name = response.data.file_name
            const upload = response.data.upload
            
            const values = filesListRef.current
 
            const new_files_list = values.map(item => 
                item.file_name === file_name 
                ? {...item, status : 100, upload: upload, variant: 'success'} 
                : item
            )
    
            setFilesList(new_files_list)

        }).catch(error => {

            console.log(error.response)

            const file_name = error.response.data.file_name
            const upload = error.response.data.upload

            const values = filesListRef.current
 
            const new_files_list = values.map(item => 
                item.file_name === file_name
                ? {...item, status : 100, upload: upload, variant: 'danger'} 
                : item
            )
    
            setFilesList(new_files_list)
        })

        
    }

    return (
        <div className='fix-container'>
            <GetCTE submitDocs={submitDocs} history={history} />
            {filesList.length > 0 ?
                <div>
                    <div className='upload-file-container'>
                        <p className='upload-file-title upload-file-name'>Nome do arquivo</p>
                        <p className='upload-file-title upload-file-size'>Size (KB)</p>
                        <p className='upload-file-title upload-file-progressbar'>Barra de progresso</p>
                    </div>
                    {filesList.map((file, index) => (
                        <FilesUpload key={index} file={file} />
                    ))}
                </div>
            : null}
        </div>
    )
}

const FilesUpload = ({ file }) => {
    return (
    <div className='upload-file-container'>
        <p className='upload-file-title upload-file-name'>{file.file_name}</p>
        <p className='upload-file-title upload-file-size'>{file.size}</p>
        <ProgressBar className='upload-file-progressbar' variant={file.variant} animated now={file.status} label={`${file.status}%`} />
    </div>
    )
}

const GetCTE = ({ loading, submitDocs, history }) => (
    <Form className="fix-container-form" noValidate onSubmit={submitDocs}>
        <div className='fix-container-buttons'>
            <Form.Group controlId="formFileMultiple"  className="mb-3">
                <Form.Label visuallyHidden>Multiple files input example</Form.Label>
                <Form.Control
                    className="mb-2"
                    type="file"
                    multiple
                    accept='.xml'
                    onChange={event => submitDocs(event)}
                />
            </Form.Group>
        </div>
    </Form>
)

export default SaveConhecimentoTransporte