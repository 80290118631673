import * as yup from 'yup';

const floatRegExp = /^([1-9]{1}[\d]{0,2}(\.[\d]{3})*(\,[\d]{0,2})?|[1-9]{1}[\d]{0,}(\,[\d]{0,2})?|0(\,[\d]{0,2})?|(\,[\d]{1,2})?)$/

// define o schema de validação do login
export const schemaRecibo = yup.object().shape({
    num_cte: yup.number().integer().typeError("É permitido apenas números.").required("O campo é obrigatório."),
    portador: yup.number().integer().typeError("É permitido apenas números.").required("O campo é obrigatório."),
    vencimento: yup.date().required("O campo é obrigatório."),
    cnpj_filial: yup.string().required("O campo é obrigatório."),
    filial: yup.string().required("O campo é obrigatório."),
    data_documento: yup.string().required("O campo é obrigatório."),
    valor: yup.string().matches(floatRegExp, "É permitido apenas números com 2 casas decimais.").required("O campo é obrigatório."),
    desc_rm: yup.string().matches(floatRegExp, "É permitido apenas números com 2 casas decimais.").required("O campo é obrigatório."),
    d_copag: yup.string().matches(floatRegExp, "É permitido apenas números com 2 casas decimais.").required("O campo é obrigatório."),
    abatimento: yup.string().matches(floatRegExp, "É permitido apenas números com 2 casas decimais.").required("O campo é obrigatório."),
    valor_liquido: yup.string().matches(floatRegExp, "É permitido apenas números com 2 casas decimais.").required("O campo é obrigatório."),    
});

// define o schema de validação do login
export const schemaMissing = yup.object().shape({
    month: yup.string().required("O campo é obrigatório."),
    year: yup.string().required("O campo é obrigatório.")
});
