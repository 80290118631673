import React from 'react'

import './styles.css'

// import background from '../../assets/image_default.jpg';

import background from '../../assets/background-page-login.jpg'

const WelcomePage = () => {

    return (
        <div className='welcome-container'>
            <div className='welcome-container-content'>
                <h2 className='welcome-title'>Bem vindo ao sistema da JM Transportes</h2>

                <div className='welcome-functions'>
                    <div className='welcome-function'>
                        <h4 className='welcome-subtitle'>CTEs Faltantes</h4>
                        <h5 className='welcome-subject'>Objetivo</h5>
                        <p className='welcome-function-description'>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Verificar quais CTES estão faltando na escrituração contábil da empresa. 
                        </p>
                        <h5 className='welcome-subject'>Modo de utilização</h5>
                        <p className='welcome-function-description'>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Selecione a aba CTE’s Faltantes no portal em seguida selecione o mês e ano e clique no botão Verificar.
                            Caso exista CTE’s faltantes, serão exibidas abaixo após o término da pesquisa ou uma mensagem indicando que está tudo certo para o período pesquisado.
                        </p>
                        <h5 className='welcome-subject'>I M P O R T A N T E</h5>
                        <p className='welcome-function-description'>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Após realizar o cadastro da CTE faltante no portal ela não será automaticamente retirada da lista das CTE’s faltantes, isso ocorrerá após as 22:00h de cada dia.
                        </p>
                    </div>
                    <br/>
                    <div className='welcome-function'>
                        <h4 className='welcome-subtitle'>Salvar CTE</h4>
                        <h5 className='welcome-subject'>Objetivo</h5>
                        <p className='welcome-function-description'>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Salvar as CTE’s que foram geradas manualmente ou que estão faltando na escrituração contábil da empresa. (É possível consultar as CTE’s faltantes no portal) 
                        </p>
                        <h5 className='welcome-subject'>Modo de utilização</h5>
                        <p className='welcome-function-description'>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Selecione a aba Salvar CTE’s no portal em seguida clique em escolher ficheiros e selecione as CTE’s no formato xml.
                        </p>
                        <h5 className='welcome-subject'>I M P O R T A N T E</h5>
                        <p className='welcome-function-description'>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Só serão processados as CTE’s no formato xml. Arquivos em outros formatos, como por exemplo: pdf, zip e outros serão desprezados, sendo necessário realizar o cadastro novamente no portal no formato correto. 
                        </p>
                    </div>
                    <br/>
                    <div className='welcome-function'>
                        <h4 className='welcome-subtitle'>Cadastrar comprovante</h4>
                        <h5 className='welcome-subject'>Objetivo</h5>
                        <p className='welcome-function-description'>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cadastrar comprovantes de pagamento que não foram realizados de forma automática pela automação. 
                        </p>
                        <h5 className='welcome-subject'>Modo de utilização</h5>
                        <p className='welcome-function-description'>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deve-se preencher os seguintes campos: 
                            <br/><br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Número da CTE (Número da CTE que o comprovante de pagamento se refere) 
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Vencimento (Data vencimento da fatura) 
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. Data do documento (Data da emissão da CTE) 
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. Valor total do frete (Valor integral do frete sem os descontos, caso existam) 
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. Valor líquido do frete (Valor líquido do frete, descontando os abatimentos, descontos, somente se existam) 
                            <br/><br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Os demais campos estão com os valores padrões, deixar desta forma. Por fim, clique no botão de salvar e aguarde a confirmação. 
                        </p>
                        <h5 className='welcome-subject'>I M P O R T A N T E</h5>
                        <p className='welcome-function-description'>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Para que esse comprovante cadastrado reflita no dashboard do power bi, será necessário atualizar o relatório. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomePage;