import React, { useState, useContext } from 'react'
import { useHistory, Link } from "react-router-dom";
import { Form, Button, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import api from '../../services/api';
import StoreContext from '../storage/context';

import './styles.css'

import logoJMTransportes from '../../assets/logo_jmtransportes_sem_fundo_preto.jpeg'
import background from '../../assets/background-page-login.jpg'

// define a tela de login da aplicação
const SignIn = () => {

    // restaga o token obtido no contexto StoreContext
    const { setToken } = useContext(StoreContext);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const dateCurrent = new Date();

    // submete o formulário de login 
    const submitSignUp = (username, password) => {
        setLoading(true)

        const data = {
            "email": username,
            "password": password
        }
        api.post('login', data)
        .then(response => {
            setToken(response.data.token)
            history.push('/financeiro');
            setLoading(false)
        })
        .catch(error => {
            console.log('message:', error.response.data.message);
            setLoading(false)
        })
    }

    // define o schema de validação do login
    const schema = yup.object().shape({
        username: yup.string().required(),
        password: yup.string().required(),
    });

    return (
        <div className="signup-container-first">
            <div className="signup-container" style={{ backgroundImage: `url(${background})` }}>
                <Formik
                    initialValues={{ username: '', password: '' }}
                    validationSchema={schema}
                    onSubmit={(values) => submitSignUp(values.username, values.password)}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <Form className="signup-container-form" noValidate onSubmit={handleSubmit}>
                            <div className="signup-container-logo">
                                <img alt="logo jmtransportes" className="signup-logo" src={logoJMTransportes} />
                            </div>
                            
                            <Form.Group className="mb-3" controlId="username">
                                <Form.Label>Usuário</Form.Label>
                                <Form.Control 
                                    type="text"
                                    name="username"
                                    placeholder="Digite seu usuário"
                                    value={values.username}
                                    onChange={handleChange}
                                    isInvalid={!!errors.username}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.username}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Senha</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder="Digite sua senha"
                                    value={values.password}
                                    onChange={handleChange} 
                                    isInvalid={!!errors.password}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <div className="d-grid gap-2">
                                {/* <Button variant="primary" type="submit" >
                                    ACESSAR
                                </Button> */}

                                <Button variant="primary" type="submit" disabled={loading}>
                                    { loading && (
                                        <Spinner
                                            as="span"
                                            animation='border'
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                    {loading && <span>{"  Acessando"}</span>}
                                    {!loading && <span>Acessar</span>}
                                </Button>
                            </div>

                            <div className="signup-container-help">
                                <Link to="/" className="signup-faq">FAQ</Link>
                            </div>

                            <div className="signup-container-header">
                                <h1 className="signup-h1">Transportadora</h1>
                                <p className="signup-p">jmtransportes.net.br © {dateCurrent.getFullYear()}</p>
                            </div>
                        </Form>
                    )}
                    
                </Formik>

            </div>
        </div>
    );
}

export default SignIn;