import React from 'react'

import HomePage from '../../components/home/index.js'

const Home = () => {

    return (
        <div>
            <HomePage />
        </div>
    );
}

export default Home;
