
import React, { useState } from 'react'
import { Form, Button, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { Formik } from 'formik';

import api from '../../services/api';

import * as schema from '../validationFields/validationFields.js';

import './styles.css'

const FixPayment = () => {
    // restaga o token obtido no contexto StoreContext

    const [loading, setLoading] = useState(false)
    const [typeAlert, setTypeAlert] = useState(false)
    const [messageAlert, setMessageAlert] = useState(false)
    const [show, setShow] = useState(false);

    const submitRecibo = (values, resetForm) => {
        setLoading(true)
        const data = {...values, "type_ingestion": "manual"}
            
        api.post('comprovante', data)
        .then(response => {
            console.log(response.data)
            setTypeAlert('success')
            setMessageAlert(response.data.message)
            setShow(true)
            setLoading(false)
            setTimeout(() => {setShow(false)}, 5000);
            resetForm();
        })
        .catch(error => {
            console.log('erro: ', error);

            setTypeAlert('danger')
            setMessageAlert(error.response.data.message)
            setShow(true)
            setLoading(false)
            setTimeout(() => {setShow(false)}, 5000);
        })
    }

    return (
        <div className='fix-container'>
            <RegisterRecibo typeAlert={typeAlert} messageAlert={messageAlert} submitRecibo={submitRecibo} loading={loading} setShow={setShow} show={show} />
        </div>
    )
}


const RegisterRecibo = ({ typeAlert, messageAlert, submitRecibo, loading, setShow, show }) => (
    <Formik
        initialValues={{
            num_cte: '',
            portador: '990',
            vencimento: '',
            data_documento: '',
            cnpj_filial: '1',
            filial: '111',
            valor: '',
            desc_rm: '0',
            d_copag: '0',
            abatimento: '0',
            valor_liquido: '',
        }}
        validationSchema={schema.schemaRecibo}
        onSubmit={(values, { resetForm }) => submitRecibo(values, resetForm)}
        validateOnChange={true}
        validateOnBlur={false}
    >
    {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
    }) => (
        <Form className="fix-container-form" noValidate onSubmit={handleSubmit}>

            {show ?
                <Alert dismissible={true} variant={typeAlert} onClose={() => setShow(false)}>
                    {messageAlert}
                </Alert>
            : null}

            <Row className="mb-3">
                <Col>
                    <Form.Group className="mb-3" controlId="num_cte">
                        <Form.Label>Número da CTE</Form.Label>
                        <Form.Control 
                            type="text"
                            name="num_cte"
                            placeholder=""
                            value={values.num_cte}
                            onChange={handleChange}
                            isInvalid={!!errors.num_cte}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.num_cte}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="portador">
                        <Form.Label>Portador</Form.Label>
                        <Form.Control 
                            type="text"
                            name="portador"
                            placeholder=""
                            value={values.portador}
                            onChange={handleChange}
                            isInvalid={!!errors.portador}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.portador}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="vencimento">
                        <Form.Label>Vencimento</Form.Label>
                        <Form.Control 
                            type="date"
                            name="vencimento"
                            placeholder="dd-mm-yyyy"
                            value={values.vencimento}
                            onChange={handleChange}
                            isInvalid={!!errors.vencimento}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.vencimento}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="data_documento">
                        <Form.Label>Data do documento</Form.Label>
                        <Form.Control 
                            type="date"
                            name="data_documento"
                            placeholder="dd-mm-yyyy"
                            value={values.data_documento}
                            onChange={handleChange}
                            isInvalid={!!errors.data_documento}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.data_documento}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Group className="mb-3" controlId="cnpj_filial">
                        <Form.Label>Código do CNPJ da filial</Form.Label>
                        <Form.Control 
                            type="text"
                            name="cnpj_filial"
                            placeholder=""
                            value={values.cnpj_filial}
                            onChange={handleChange}
                            isInvalid={!!errors.cnpj_filial}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.cnpj_filial}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="filial">
                        <Form.Label>Código da filial</Form.Label>
                        <Form.Control 
                            type="text"
                            name="filial"
                            placeholder=""
                            value={values.filial}
                            onChange={handleChange}
                            isInvalid={!!errors.filial}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.filial}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Group className="mb-3" controlId="valor">
                        <Form.Label>Valor total do frete</Form.Label>
                        <Form.Control 
                            type="text"
                            name="valor"
                            placeholder=""
                            value={values.valor}
                            onChange={handleChange}
                            isInvalid={!!errors.valor}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.valor}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="desc_rm">
                        <Form.Label>desc_rm *</Form.Label>
                        <Form.Control 
                            type="text"
                            name="desc_rm"
                            placeholder=""
                            value={values.desc_rm}
                            onChange={handleChange}
                            isInvalid={!!errors.desc_rm}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.desc_rm}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="d_copag">
                        <Form.Label>d_copag *</Form.Label>
                        <Form.Control 
                            type="text"
                            name="d_copag"
                            placeholder=""
                            value={values.d_copag}
                            onChange={handleChange}
                            isInvalid={!!errors.d_copag}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.d_copag}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="abatimento">
                        <Form.Label>Abatimento</Form.Label>
                        <Form.Control 
                            type="text"
                            name="abatimento"
                            placeholder=""
                            value={values.abatimento}
                            onChange={handleChange}
                            isInvalid={!!errors.abatimento}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.abatimento}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="valor_liquido">
                        <Form.Label>Valor liquido do frete</Form.Label>
                        <Form.Control 
                            type="text"
                            name="valor_liquido"
                            placeholder=""
                            value={values.valor_liquido}
                            onChange={handleChange}
                            isInvalid={!!errors.valor_liquido}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.valor_liquido}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mb-3">
                <div className="fix-container-btn">
                    <Button className='fix-btn-save' variant="primary" type="submit" disabled={loading}>
                        { loading && (
                            <Spinner
                                as="span"
                                animation='border'
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        {loading && <span>{" Salvando"}</span>}
                        {!loading && <span>Salvar</span>}
                    </Button>
                </div>
            </Row>

            </Form>
        )}
        
    </Formik>
)

export default FixPayment;