import React, { useContext } from 'react'
import { IoMdExit } from 'react-icons/io'
import { useHistory } from "react-router-dom";
import { Button } from 'react-bootstrap';

import StoreContext from '../storage/context';

import logo from '../../assets/logo_jmtransportes_sem_fundo.png'

import './styles.css'

const HeaderAdmin = () => {
    const { setToken } = useContext(StoreContext);

    const history = useHistory();

    return (
        <div className='header-admin-container'>
            <div className='header-admin-content'>
                <div onClick={() => history.push('/financeiro')}>
                    <img className="logo-admin" src={logo} alt="logo" />
                </div>
                <div className='header-admin-options'>
                    <a href="/conhecimento_transporte_eletronico_faltantes" className='header-admin-option'>CTEs Faltantes</a>
                    <a href="/registra_recibo" className='header-admin-option'>Cadastrar Comprovante</a>
                    <a href="/salva_conhecimento_transporte_eletronico" className='header-admin-option'>Salvar CTEs</a>
                    <div>
                        <Button className='header-admin-logout' variant="danger" onClick={() => setToken("")}>
                            <IoMdExit className='header-admin-btn-icon' size={22} />
                            <span>Sair</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderAdmin;