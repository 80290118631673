import React from 'react'
import { Form, Button, Col, Spinner } from 'react-bootstrap'

import './styles.css'

// componente input sem validacao
const ButtonCustom = ({ 
    type,
    label, 
    name, 
    loading
    }) => (

    <Col md>
        <div className="button-container">
            <div className='button-label'/>
            <Button className='fix-btn-save' variant="primary" type="submit" disabled={loading}>
                { loading && (
                    <Spinner
                        as="span"
                        animation='border'
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                )}
                {loading && <span>{" Verificando"}</span>}
                {!loading && <span>Verificar</span>}
            </Button>
        </div>
    </Col>
)

export default ButtonCustom;