import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { FaFacebookSquare, FaInstagramSquare, FaPhoneAlt } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";
import { MdPlace } from "react-icons/md";
import { Carousel, Ratio } from 'react-bootstrap'

import './styles.css';

import logo from '../../assets/logo.jpg';
import quemSomos from '../../assets/quem-somos-large.jpeg';
import frotaCompleta from '../../assets/frota_completa_reduzida.jpeg';
import imageEntardecer from '../../assets/entardecer_jmtransportes.jpeg';
import imageNovoCaminhao from '../../assets/novo_caminhao_melhorada.jpeg';
import movie_novo_caminhao from '../../assets/novo_caminhao.mp4';

const YOUR_SERVICE_ID="service_ua7lmgm"
const YOUR_TEMPLATE_ID="template_jcp6y2r"
const YOUR_USER_ID="user_7ii43qGpvjhU0NZSXZpY9"

function HomePage() {

  const [menu, setMenu] = useState("header-mobile-hidden");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");


  const onSubmit = (event) => {
    event.preventDefault();

    emailjs.sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, event.target, YOUR_USER_ID)
    .then((result) => {
        if (result.text === "OK") {
          alert("Obrigado, em breve entraremos em contato!");
          cleanFields();
        }else {
          alert("Ops, algo deu errado! tente novamente mais tarde");
        }
    }, (error) => {
        console.log(error.text);
        alert("Ops, algo deu errado! tente novamente mais tarde");
    });
  }

  const cleanFields = () => {
    setMessage("");
    setName("");
    setPhone("");
    setEmail("");
  }
 
    return (
    <div className="home-container">
      <header className="header">

        <div className="header-social-media">
          <a
            href="https://web.facebook.com/Jm-transportes-103401622146425"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare size={20} className="header-social-media-facebook" />
          </a>
          <a href="https://www.instagram.com/jmtransportes85" target="_blank" rel="noreferrer">
            <FaInstagramSquare size={20} className="header-social-media-instagram" />
          </a>
        </div>

        <div className="header-container">
          <div className="header-content">
            <div className="header-icon" onClick={()=> setMenu("header-mobile-show")}>
              <FiMenu size={30} />
            </div>

            <img className="logo" src={logo} alt="logo" />

            <div>
              <nav className="menu">
                    <div className='home-container-item' >
                        <a className='home-item' href="#Services">Notícias</a>
                    </div>
                    <div className='home-container-item'>
                        <a className='home-item' href="#Quem somos">Quem Somos</a>
                    </div>
                    <div className='home-container-item'>
                        <a className='home-item' href="#Contato">Contato</a>
                    </div>
              </nav>
            </div>
          </div>

        <div id="header-mobile" className={menu} >
          <div className="header-mobile-options">
            <div className="header-mobile-close" onClick={() => setMenu("header-mobile-hidden")}>
              <AiOutlineCloseCircle size={30} />
            </div>
            <ul>
              <li><a href="#Services">Notícias</a></li>
              <li><a href="#Quem somos">Quem Somos</a></li>
              <li><a href="#Contato">Contato</a></li>
            </ul>
          </div>
        </div>
        
        </div>
      </header>

      <div className="main-container">

        <div id="Services" className="services-container">
          <Carousel>
            <Carousel.Item interval={1000}>
              <img
                className="d-block w-100 carousel-img"
                src={imageEntardecer}
                alt="First slide"
              />
              <Carousel.Caption>
                <h3>Cliente em 1º lugar</h3>
                <p>Atender o cliente com excelência é nossa meta.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={500}>
              <img
                className="d-block w-100 carousel-img"
                src={frotaCompleta}
                alt="Second slide"
              />
              <Carousel.Caption>
                <h3>Investindo Constantemente na Modernização</h3>
                <p>Estamos constantemente investindo em novos veículos e equipamentos para atender ainda melhor nossos cliente.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 carousel-img"
                src={imageNovoCaminhao}
                alt="Third slide"
              />
              <Carousel.Caption>
                <h3>Frota Diversificada</h3>
                <p>Aqui você encontra veiculos de pequeno, médio e grande porte, inclusive refrigerados. Entre em contato com um dos nossos especialistas.</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
                  
        </div>


        <div id="Quem somos" className="whoare-container">
          <div className="container-default">
            <div className="whoare-container-text">
              <h1 className="whoare-content-title">Pensando no Cliente em 1º Lugar</h1>
              <p className="whoare-content-text">Atender o cliente com excelência é nossa maior meta.</p>
            </div>
            <div className="whoare-container-img">
              <img className="whoare-img" src={quemSomos} alt="who are" />
            </div>
          </div>
        </div>


        {/* <div style={{ width: 1140, height: 'auto' }}>
          <Ratio aspectRatio="16x9">
            <video type="video/mp4" src={movie_novo_caminhao} />
          </Ratio>
        </div> */}
        <div id="Quem somos" className="whoare-container">
          <div className="container-default">
            <video width="750" height="500" controls className="whoare-container-img">
              <source src={movie_novo_caminhao} type="video/mp4"/>
            </video>
            <div className="whoare-container-text">
              <h1 className="whoare-content-title">Investindo na Modernização da Frota</h1>
              <p className="whoare-content-text">Estamos constantemente investindo em novos veículos e equipamentos para atender ainda melhor nossos cliente.</p>
            </div>
          </div>
        </div>

        {/* <!-- FORMULARIO DE CONTATO --> */}
        <div id="Contato" className="container-default container-contact">
          <div className="contact-infos-container">
            <h1 className="contact-header">Quer saber como a JM Transportes pode auxiliar nos seus problemas logisticos?</h1>
            <p className="contact-title">Contate nossos especialistas</p>

            <div className="contact-container-field">
              <FaPhoneAlt size={15} />
              <p className="contact-phone">+55 (11) 9 6782-4540</p>
            </div>

            <div className="contact-container-field">
              <HiOutlineMail size={18} />
              <p className="contact-email">operacional@jmtransportes.net.br</p>
            </div>

            <div className="contact-container-field">
              <MdPlace size={18} />
              <p className="contact-address">Estamos localizados em Av. Morvan Dias de Figueiredo, 6169 - Vila Maria, São Paulo - SP, 02170-901</p>
            </div>
          </div>

          <form id="my-forms" className="forms-content" onSubmit={onSubmit}>
            <div className="forms-container-field">
              <input name="user_name" className="forms-contact-input" value={name} type="text" placeholder="Seu nome" onChange={(event)=>setName(event.target.value)}></input>
            </div>
            <div className="forms-container-field">
              <input name="contact_number" className="forms-contact-input" value={phone} type="text" placeholder="Telefone" onChange={(event)=>setPhone(event.target.value)}></input>
            </div>
            <div className="forms-container-field">
              <input name="user_email" className="forms-contact-input" value={email} type="email" placeholder="E-mail" onChange={(event)=>setEmail(event.target.value)}></input>
            </div>
            <div className="forms-container-field">
              <textarea name="message" value={message} className="forms-contact-input forms-contact-message" placeholder="Deixe sua mensagem aqui" cols="50" rows="6" wrap="hard" onChange={(event)=>setMessage(event.target.value)}></textarea>
            </div>
            <button className="forms-button">Enviar</button>
          </form>
        </div>
      </div>

      {/* <!-- FOOTER --> */}
      <footer className="footer-container">
        <div className="footer-container-top">
          <div className="footer-container-maps">
            <a href="#Services" className="footer-container-text home-item">Notícias</a>
            <a href="#Quem somos" className="footer-container-text home-item">Quem somos</a>
            <a href="#Contato" className="footer-container-text home-item">Contato</a>
          </div>
          <div className="footer-content-local">
            <h1 className="footer-container-title">Informações de contato</h1>
            {/* <h1 className="footer-container-text">Comercial (11) 9 6782-4540</h1> */}
            <h1 className="footer-container-text">WhatsApp (11) 9 6782-4540</h1>
          </div>
          <div className="footer-content-local">
            <h1 className="footer-container-title">Localidades atendidas</h1>
            <h1 className="footer-container-text">Capital, Grande ABC, Litoral e Interior</h1>
          </div>
        </div>

        <div className="footer-container-bottom">
          <div className="footer-content">
            <h1 className="footer-title">JM TRANSPORTES | QUALIDADE E CONFIANÇA</h1>
            <div className="footer-social-media">
              <a href="https://www.google.com.br" target="_blank" rel="noreferrer">
                <FaFacebookSquare size={25} className="footer-social-media-facebook" />
              </a>
              <a href="https://www.google.com.br" target="_blank" rel="noreferrer">
                <FaInstagramSquare size={25} className="footer-social-media-instagram" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default HomePage;
