
import React, { useState, useRef } from 'react'
import { useHistory } from "react-router-dom";
import { Form, ProgressBar, Row, Col, Button, Spinner, Alert } from 'react-bootstrap';
import { Formik } from 'formik';

import api from '../../services/api';

import * as schema from '../validationFields/validationFields.js';
import SelectWithValidation from '../fields/selectWithValidation/index.js'
import ButtonCustom from '../fields/button/index.js'

import './styles.css'

const ConhecimentoTransporteFaltantes = () => {

    const [filesList, setFilesList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [typeAlert, setTypeAlert] = useState(false)
    const [messageAlert, setMessageAlert] = useState(false)
    const [show, setShow] = useState(false);

    const [ctesFaltantes, setCtesFaltantes] = useState([]);

    let history = useHistory();

    const months_ = {
        "Janeiro": "01", 
        "Fevereiro": "02",
        "Março": "03",
        "Abril": "04",
        "Maio": "05",
        "Junho": "06",
        "Julho": "07",
        "Agosto": "08",
        "Setembro": "09",
        "Outubro": "10",
        "Novembro": "11",
        "Dezembro": "12"
    }

    const months = [
        "",
        "Janeiro", 
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
    ]

    const current_year = new Date().getFullYear();

    const years = [
        "",
        current_year-6,
        current_year-5,
        current_year-4,
        current_year-3,
        current_year-2,
        current_year-1,
        current_year-0
    ]

    const submitMissingCtes = (values) => {
        setLoading(true)

        const lastDateOfMonth = new Date(parseInt(values.year), parseInt(months_[values.month]), 0);

        const lastDayOfMonth = lastDateOfMonth.getUTCDate().toString()
        const from_date = values.year + "-" + months_[values.month] + "-01 00:00:00"
        const to_date   = values.year + "-" + months_[values.month] + "-" + lastDayOfMonth + " 23:59:59"

        api.get('ctes', {
            params: {
                from_date: from_date,
                to_date: to_date
            }
        })
        .then(response => {
            console.log(response.data)
            setLoading(false)
            setCtesFaltantes(response.data)

            if (response.data.length === 0){
                setTypeAlert('success')
                setMessageAlert("Não exitem ctes faltantes para esse periodo.")
                setShow(true)
                setTimeout(() => {setShow(false)}, 5000);
            }
        })
        .catch(error => {
            console.log('erro: ', error);
            setTypeAlert('danger')
            setMessageAlert(error.response.data.message)
            setShow(true)
            setLoading(false)
            setTimeout(() => {setShow(false)}, 5000);
        })
    }

    return (
        <div className='fix-container'>
            {show ?
                <Alert dismissible={true} variant={typeAlert} onClose={() => setShow(false)}>
                    {messageAlert}
                </Alert>
            : null}

            <SelectMonthAndYear loading={loading} months={months} years={years} submitMissingCtes={submitMissingCtes} />

            <CtesFaltantes ctesFaltantes={ctesFaltantes} />
        </div>
    )
}


const CtesFaltantes = ({ ctesFaltantes }) => (
    <div className='ctes-container'>
        {ctesFaltantes.map((element, index) => (
            <div key={index} className="ctes-container-faltantes">{element}</div>
        ))}
    </div>
)


const SelectMonthAndYear = ({ loading, submitMissingCtes, months, years }) => (
    <Formik
        initialValues={{
            month: '',
            year: '',
        }}
        validationSchema={schema.schemaMissing}
        onSubmit={(values) => submitMissingCtes(values)}
        validateOnChange={true}
        validateOnBlur={true}
    >
    {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
    }) => (
        <Form className="fix-container-form" noValidate onSubmit={handleSubmit}>
            <Row>
                <SelectWithValidation Col={Col}
                    type="text"
                    label="Selecione o mês"
                    name="month"
                    placeholder=""
                    value={values.month}
                    error={errors.month}
                    handleChange={handleChange}
                    options={months}
                />
                <SelectWithValidation Col={Col}
                    type="text"
                    label="Selecione o ano"
                    name="year"
                    placeholder=""
                    value={values.year}
                    error={errors.year}
                    handleChange={handleChange}
                    options={years}
                />

                <ButtonCustom
                    type={"type"} 
                    name={"name"} 
                    loading={loading}
                />
                <Col xs={7}/>
            </Row>
        </Form>
    )}
    </Formik>
)

export default ConhecimentoTransporteFaltantes