import React from 'react'

import HeaderAdmin from '../../components/headerAdmin';
import WelcomePage from '../../components/welcome';

import './styles.css'

const HomeAdmin = () => {

    return (
        <div>
            <HeaderAdmin />
            <WelcomePage />
        </div>
    )
}

export default HomeAdmin;