
import React from 'react'

import HeaderAdmin from '../../components/headerAdmin';
import ConhecimentoTransporteFaltantes from '../../components/conhecimentoTransporteFaltantes';

import './styles.css'

const CtesMissing = () => {

    return (
        <div>
            <HeaderAdmin />
            <ConhecimentoTransporteFaltantes />
        </div>
    )
}

export default CtesMissing;